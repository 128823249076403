<template>
  <section class="space-1 bg-light login-section">
    <div class="container">
      <div class="d-flex justify-content-center mb-5">
        <router-link to="/">
          <img class="logo d-flex"
               src="@/assets/img/brand/logo.svg" alt="Logo">
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 card card-body py-5 px-5">
          <div class="px-3 py-3">
            <div class="mb-4 text-center">
              <h3>
                <i v-if="emailVerified" class="fa fa-check-circle"></i>
                Email Verification
                <span v-if="emailVerified">Done!</span>
              </h3>
            </div>

            <div v-if="!emailVerified">
              <div>
                Please wait while we verify your email address...
              </div>
              <div class="mt-2 progress-line blue-bar"></div>
            </div>

            <div v-if="emailVerified" class="text-center">
              <div class="mb-3">
                Your email has been verified and you are all set!
              </div>
              <router-link to="/dashboard" class="btn btn-primary wide">Continue</router-link>

              <div class="mt-3">
                <img class="onboarding-image"
                     src="@/assets/img/verify/dance.webp" alt="People Dancing" />
                <div class="image-attribution text-left">The Office / Peacock</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import userInfo from '@/app/user-info';
import utils from '@/app/utils';

export default {
  components: { },
  data() {
    return {
      emailToVerify: null,
      code: null,
      emailVerified: false,
      inProgress: false,
    };
  },
  async mounted() {
    this.emailToVerify = this.$route.params.email;
    this.code = this.$route.params.code;

    this.inProgress = true;

    try {
      await userInfo.verifyEmail(this.emailToVerify, this.code);
      this.inProgress = false;
      this.emailVerified = true;
    } catch (error) {
      utils.showToastError('Could not verify email',
        'An error occurred and I could not verify your email. Can you try again please?',
        true);
    }
  },
};
</script>
